import React, { memo } from "react";
import { Row } from "antd";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";

const Transaction = ({ id }) => {
  function camelCaseToCapitalized(input) {
    if (!input) return ""
    // Add space before each uppercase letter and capitalize the first letter of each word
    const result = input
      .replace(/([A-Z])/g, ' $1')  // Add space before uppercase letters
      .replace(/^./, str => str.toUpperCase())  // Capitalize the first letter
      .trim(); // Remove any leading/trailing spaces
    
    return result;
  }
  return (
    <>
      <>
        <Row>
          <CRUDComponent
            GET={{
              API: CONSTANTS.API.trajection.getAll,
              extraQuery: { userId: id },
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.id,
                    source: camelCaseToCapitalized(data?.source),
                    statusD: (
                      <>
                        {data?.status === "Success" ? (
                          <span className=" text-green-600">Success</span>
                        ) : data?.status === "Fail" ? (
                          <span className=" text-red-500">Fail</span>
                        ) : data?.status}
                      </>
                    ),
                  };
                });
              },
              column: CONSTANTS.TABLE.USER_TRANSACTION,
            }}
            isSearch
          />
        </Row>
      </>
    </>
  );
};

export default memo(Transaction);
