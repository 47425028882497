import { Card, Modal, Row, Select, Switch, notification } from "antd";
import React, { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { convertUTCToLocal, PERMISSION } from "../../../util/functions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userExportData, userVerificationData } from "../../../util/exportData";
import WorkProof from "../../../component/taskApproval/WorkProof";
import useHttp from "../../../hooks/use-http";
import { RxCrossCircled } from "react-icons/rx";
import { GrInProgress } from "react-icons/gr";

const USER_APPROVAL_OPTION = ["Not Verified", "Pending", "Verified", "Failed"];

const Verification = ({ admindetails, permissionId }) => {
  const api = useHttp();
  const [docList, setDocList] = useState(null);
  const navigate = useNavigate();
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];

  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Document Verification List</Heading>
          <Row>
            <CRUDComponent
              title="User"
              exportData={userVerificationData}
              accessPermission={accessPermission}
              GET={{
                API: CONSTANTS.API.user.getAll,
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      no: `U${data?.id.toString().padStart(7, "0")}`,
                      view: {
                        id: data?.id,
                        onClick: (id) => {
                          navigate(`/app/user/${id}`);
                        },
                      },
                      identify: (
                        <>
                          {data?.isGovermentIdVerified === "Verified" ? (
                            <CheckCircleOutlined className="text-2xl text-[#007BFF]" />
                          ) : data?.isGovermentIdVerified === "Failed" ? (
                            <RxCrossCircled className="text-2xl text-red-500" />
                          ) : (
                            <GrInProgress className="text-2xl text-slate-500" />
                          )}
                        </>
                      ),
                      userImage: { image: data?.image, name: data?.name },
                      document: {
                        id: data?.id,
                        onClick: (id) => {
                          const documentList = [
                            data?.idCard,
                            data?.aadharCard,
                            data?.aadharCardBack,
                            data?.panCard,
                          ].filter((url) => url !== null);
                          documentList?.length
                            ? setDocList({
                                doc: documentList,
                                id: data?.id,
                                isGovermentIdVerified:
                                  data?.isGovermentIdVerified,
                                Setrefresh: Setrefresh,
                              })
                            : notification.warning({
                                message: "Data Not Found",
                                duration: "3",
                              });
                        },
                      },
                      isGovermentIdVerified: (
                        <Select
                          className="w-32"
                          placeholder={"Select"}
                          disabled={PERMISSION[accessPermission] === "READ"}
                          value={data?.isGovermentIdVerified}
                          onChange={(value, f) => {
                            const UpdateAPIEnd = {
                              ...CONSTANTS?.API.user.update,
                            };
                            UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${data?.id}`;
                            API.sendRequest(
                              UpdateAPIEnd,
                              () => {
                                Setrefresh((prev) => !prev);
                              },
                              { isGovermentIdVerified: value },
                              `Verification Updated Successfully`
                            );
                          }}
                        >
                          {USER_APPROVAL_OPTION.map((item) => (
                            <Select.Option key={`${item}`}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      ),
                      isIdentifyD: data?.isGovermentIdVerified,
                    };
                  });
                },
                column: CONSTANTS.TABLE.USER_VERIFICATION,
              }}
              isSearch
            />
          </Row>
        </Card>
      </div>
      <Modal
        title={"View Documents"}
        centered
        open={docList}
        onCancel={() => {
          setDocList(null);
        }}
        width={800}
        footer={null}
      >
        <WorkProof MultipleImages={docList?.doc} />
        <div className="flex justify-end mt-5 items-center">
          Verification
          <Switch
            size={"small"}
            className="ms-2"
            onChange={(val) => {
              const UpdateAPIEnd = {
                ...CONSTANTS?.API.user.update,
              };
              UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${docList?.id}`;
              api.sendRequest(
                UpdateAPIEnd,
                () => {
                  docList?.Setrefresh((prev) => !prev);
                },
                { isGovermentIdVerified: val ? "Verified" : "Not Verified" },
                `Verification Updated Successfully`
              );
            }}
            defaultChecked={docList?.isGovermentIdVerified === "Verified"}
            disabled={PERMISSION[accessPermission] === "READ"}
          />
        </div>
      </Modal>
    </>
  );
};

export default Verification;
