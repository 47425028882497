import { Card, Row, Tabs } from "antd";
import React from "react";
import Heading from "../../../component/common/Heading";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import DeleteUser from "./DeleteUser";
import UserList from "./UserList";

const User = ({ admindetails, permissionId }) => {
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];

  const menus = {
    list: <UserList accessPermission={accessPermission} />,
    deletedList: <DeleteUser accessPermission={accessPermission} />,
  };

  return (
    <Card className="my-5">
      <Heading>User List</Heading>
      <Row>
        <Tabs
          className="p-0 mt-5"
          size="middle"
          type="card"
          items={CONSTANTS.TAB_MENU.USER_LISR.map((ele) => {
            return {
              label: `${ele?.Label}`,
              key: `${ele?.id}`,
              children: menus[ele?.id],
            };
          })}
        />
      </Row>
    </Card>
  );
};

export default User;
