import React, { useRef } from "react";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { getAuthToken } from "../../util/API/authStorage";
// const config = {
//   buttons: ["bold", "italic", "link", "unlink", "underline", "source"],
// };

const TextEditor = ({ value, onChange, placeholder }) => {
  const editor = useRef(null);
  const token = getAuthToken();

  const config = {
    uploader: {
      insertImageAsBase64URI: false, // Prevent default base64 behavior
      url: `${process.env.REACT_APP_BASE_URL}/task/add-image-into-s3`,
      format: "json",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      filesVariableName: function () {
        return "image"; // Manually set the key name for the image
      },
      process: function (response) {
        return {
          path: response?.image, // Assume the response has the image URL, adjust as needed
        };
      },
      isSuccess: function (response) {
        return response && response?.status === "success"; // Customize based on API response
      },
      error: function (error) {
        console.error("Upload Error:", error);
      },
      data: function (files) {
        // Create a new FormData object for uploading image under 'image' key
        const formData = new FormData();
        formData.append("image", files[0]); // Ensure "image" key for the uploaded file
        return formData;
      },
      defaultHandlerSuccess: function (data) {
        if (data?.path) {
          this.selection.insertImage(data?.path, null, 250);
        } else {
          this.message.error("Upload failed");
        }
      },
    },
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    height: "250px",
    toolbarAdaptive: false, // Ensure the toolbar does not adapt to the screen width
  };

  // const [content, setContent] = useState("");
  return (
    <>
      <div className="relative z-10">
        <JoditEditor
          ref={editor}
          value={value}
          config={config}
          tabIndex={1} // tabIndex of textarea
          // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onBlur={onChange}
        />
      </div>
    </>
  );
};

export default TextEditor;
