import React from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { CheckCircleOutlined } from "@ant-design/icons";
import { RxCrossCircled } from "react-icons/rx";
import { GrInProgress } from "react-icons/gr";
import CRUDComponent from "../../../component/common/CRUD-Component";

function DeleteUser({ accessPermission }) {
  return (
    <CRUDComponent
      title="User"
      // exportData={userExportData}
      accessPermission={accessPermission}
      GET={{
        API: CONSTANTS.API.user.deletedUserList,
        DataModifier: (res, API, Setrefresh) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `U${data?.id.toString().padStart(7, "0")}`,
              //   view: {
              //     id: data?.id,
              //     onClick: (id) => {
              //       navigate(`/app/user/${id}`);
              //     },
              //   },
              userImage: { image: data?.image, name: data?.name },
              city: data?.city,
              // dob: convertUTCToLocal(data?.DOB, "DD-MM-YYYY"),
              identify: (
                <>
                  {data?.isGovermentIdVerified === "Verified" ? (
                    <CheckCircleOutlined className="text-2xl text-[#007BFF]" />
                  ) : data?.isGovermentIdVerified === "Failed" ? (
                    <RxCrossCircled className="text-2xl text-red-500" />
                  ) : (
                    <GrInProgress className="text-2xl text-slate-500" />
                  )}
                </>
              ),
              isIdentifyD: data?.isGovermentIdVerified,
            };
          });
        },
        column: CONSTANTS.TABLE.USERS?.filter(
          (field) =>
            !["editTableRow", "toggle", "affiliate", "view"].includes(
              field?.dataIndex
            )
        ),
      }}
      isSearch
    />
  );
}

export default DeleteUser;
