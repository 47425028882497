import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Popconfirm,
  Row,
  Spin,
} from "antd";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import FormFields from "../../../component/common/FormFields";
import { IoIosNotificationsOutline } from "react-icons/io";
import CRUDComponent from "../../../component/common/CRUD-Component";
import Heading from "../../../component/common/Heading";
import moment from "moment";

const statu = [
  { Label: "All", value: "-1", id: "All" },
  { Label: "In-Progress", value: "1", id: "InProgress" },
  { Label: "Pending", value: "2", id: "Pending" },
  { Label: "Accepted", value: "3", id: "Accepted" },
  { Label: "Rework", value: "4", id: "Rework" },
  { Label: "Reject", value: "5", id: "Reject" },
];

function Notification({ admindetails, permissionId }) {
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  const api = useHttp();
  const [form] = Form.useForm();
  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.sendRequest(
      CONSTANTS.API.Task_Management.getAll,
      (res) => {
        const option = res?.data?.rows?.map((task) => {
          return { Label: task?.name, value: task?.id, id: task?.id };
        });
        setTaskList(option);
        setLoading((pre) => !pre);
      },
      { status: "Active" }
    );
  }, []);

  const getLabels = (values) => {
    return statu
      .filter((item) => values.includes(item.value)) // Filter based on the value
      .map((item) => item.Label) // Get the labels
      .join(", "); // Concatenate them into a string
  };

  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Schedule Notification</Heading>
          <Row>
            <CRUDComponent
              title="User"
              accessPermission={accessPermission}
              GET={{
                API: CONSTANTS.API.scheduleNotification.getAll,
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      no: `U${data?.id.toString().padStart(7, "0")}`,
                      taskName: data?.task?.name,
                      targetUser: getLabels(data?.targetUsers),
                      localTime: moment
                        .utc(data?.time, "HH:mm:ss")
                        .local()
                        .format("HH:mm:ss"),
                      status: {
                        checked: data?.status !== "Active",
                        id: data?.id,
                        onClick: (id, val) => {
                          const UpdateAPIEnd = {
                            ...CONSTANTS?.API.scheduleNotification.update,
                          };
                          UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                          API.sendRequest(
                            UpdateAPIEnd,
                            () => {
                              Setrefresh((prev) => !prev);
                            },
                            { status: val ? "Active" : "Inactive" },
                            `Notification ${
                              val ? "Active" : "Inactive"
                            } Successfully`
                          );
                        },
                      },
                    };
                  });
                },
                column: CONSTANTS.TABLE.NOTIFICATION,
              }}
              //   isSearch
              isSearch
              CREATE={{
                API: CONSTANTS.API.scheduleNotification.add,
                isFormData: true,
                message: "Created Notification successfully",
                modaltitle: "Add New Notification",
                modalFields: CONSTANTS.FORM_FIELD.SCHEDULE_NOTIFICATION?.map(
                  (field) => {
                    if (field?.id === "taskId") field.option = taskList;
                    return field;
                  }
                ),
                // payloadModifier: (res) => res,
              }}
              UPDATE={{
                API: CONSTANTS.API.scheduleNotification.update,
                isFormData: true,
                message: "Edit Notification successfully",
                modaltitle: "Edit Notification",
                modalFields: CONSTANTS.FORM_FIELD.SCHEDULE_NOTIFICATION?.map(
                  (field) => {
                    if (field?.id === "taskId") field.option = taskList;
                    return field;
                  }
                ),
                // payloadModifier: (res) => res,
              }}
              DELETE={{
                API: CONSTANTS.API.scheduleNotification.delete,
                message: "Deleted Notification Successfully",
              }}
            />
          </Row>
        </Card>
      </div>
    </>
  );
}

export default Notification;
