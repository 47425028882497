import React from "react";
import { Card, Row } from "antd";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";
import { roleManagement } from "../../../util/exportData";

const RoleManagement = ({ admindetails, permissionId }) => {
  const navigate = useNavigate();
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[2];
  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Role Management</Heading>
          <Row>
            <CRUDComponent
              exportData={roleManagement}
              accessPermission={accessPermission}
              GET={{
                API: CONSTANTS.API.Role_Management.getAll,
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      no: `R${data?.id.toString().padStart(7, "0")}`,
                      role: "Sub-Admin",
                      view: {
                        id: data?.id,
                        onClick: (id) => {
                          navigate(`/app/role-management/access-form/${id}`);
                        },
                      },
                    };
                  });
                },
                column: CONSTANTS.TABLE.ROLE_MANAGEMENT,
              }}
              isSearch
              UPDATE={{
                API: CONSTANTS.API.Role_Management.update,
                message: "Edit Sub Admin successfully",
                modaltitle: "Edit Sub Admin",
                modalFields: CONSTANTS.FORM_FIELD.EDIT_ROLE_MANAGEMENT_MODAL,
                payloadModifier: (res) => {
                  console.log(res);
                  return {
                    email: res?.email,
                    name: res?.name,
                    password: res?.passwordD ? res?.passwordD : undefined,
                  };
                },
              }}
              CREATE={{
                API: CONSTANTS.API.Role_Management.add,
                message: "Created Sub Admin successfully",
                modaltitle: "Add Sub Admin",
                modalFields: CONSTANTS.FORM_FIELD.ROLE_MANAGEMENT_MODAL,
                // payloadModifier: (res) => res,
              }}
              DELETE={{
                API: CONSTANTS.API.Role_Management.delete,
                message: "Deleted Sub Admin Successfully",
              }}
            />
          </Row>
        </Card>
      </div>
    </>
  );
};

export default RoleManagement;
