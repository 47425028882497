import React, { memo, useState } from "react";
import { Row } from "antd";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { convertUTCToLocal, PERMISSION } from "../../../../util/functions";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import useHttp from "../../../../hooks/use-http";

const ReferredUser = ({ referenceBy, accessPermission }) => {
  const [openResonModal, setOpenResonModal] = useState(null);
  const [reload, setReload] = useState(false);

  const api = useHttp();

  const OnFormSubmitBtn = (res) => {
    const payload = {
      isBlocked: !!openResonModal,
      blockReason: res?.blockReason ?? "",
    };
    const UpdateAPIEnd = {
      ...CONSTANTS?.API.user.update,
    };
    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${openResonModal ?? res}`;
    api.sendRequest(
      UpdateAPIEnd,
      () => {
        setOpenResonModal(null);
        setReload((pre) => !pre);
      },
      payload,
      `User ${payload?.isBlocked ? "Blocked" : "Unblock"} Successfully`
    );
  };

  return (
    <>
      <>
        <Row>
          <CRUDComponent
            reload={reload}
            GET={{
              API: CONSTANTS.API.user.getAll,
              extraQuery: { referenceCode: referenceBy },
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.no,
                    userImage: { image: data?.image, name: data?.name },
                    isBlocked: {
                      checked: !data?.isBlocked,
                      id: data?.id,
                      isDisable: PERMISSION[accessPermission] === "READ",
                      onClick: (id, val) => {
                        val ? setOpenResonModal(id) : OnFormSubmitBtn(id);
                      },
                    },
                  };
                });
              },
              column: CONSTANTS.TABLE.REFERRED_HISTORY,
            }}
            isSearch
          />
        </Row>
        <ModalFormCreator
          loading={api.isLoading}
          open={openResonModal}
          onCreate={OnFormSubmitBtn}
          onCancel={() => {
            setOpenResonModal(null);
          }}
          menuFields={CONSTANTS.FORM_FIELD.USER_BLOCK_FORM}
          name={"Reason"}
          SubmitName={"Submit"}
        />
      </>
    </>
  );
};

export default memo(ReferredUser);
