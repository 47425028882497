import React, { useState } from "react";
import { Card, Row, Tabs } from "antd";
import Heading from "../../../component/common/Heading";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import DeviceList from "./DeviceList";

function UserDevice({ admindetails, permissionId }) {
  const [reload, setReload] = useState(false);
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];

  const menus = {
    deviceList: (
      <DeviceList
        accessPermission={accessPermission}
        setReload={setReload}
        reload={reload}
      />
    ),
    blockDevice: (
      <DeviceList
        showBlockedOnly={true}
        accessPermission={accessPermission}
        setReload={setReload}
        reload={reload}
      />
    ),
  };

  return (
    <div>
      <Card className="my-5">
        <Heading>Device Management</Heading>
        <Row>
          <Tabs
            onChange={(e) => setReload((pre) => !pre)}
            className="p-0 mt-5"
            size="middle"
            type="card"
            items={CONSTANTS.TAB_MENU.DEVICE.map((ele) => {
              return {
                label: `${ele.Label}`,
                key: `${ele.id}`,
                children: menus[ele?.id],
              };
            })}
          />
        </Row>
      </Card>
    </div>
  );
}

export default UserDevice;
