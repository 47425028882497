import { HiOutlineUserGroup } from "react-icons/hi2";
import { VscGraph } from "react-icons/vsc";
import { IoImageOutline } from "react-icons/io5";
const data = [
  {
    id: "analytics",
    icon: <VscGraph />,
    label: "Analytics",
  },

  // {
  //   id: "users",
  //   icon: <AiOutlineUser />,
  //   label: "Users",
  // },
  {
    id: "role-management",
    icon: <HiOutlineUserGroup />,
    label: "Role Management",
  },
  {
    id: "banner",
    icon: <IoImageOutline />,
    label: "Banner",
  },
  // {
  //   id: "pets",
  //   icon: <FaDog />,
  //   label: "Pets",
  // },
  // {
  //   id: "benchmark",
  //   icon: <HiOutlinePresentationChartLine />,
  //   label: "Benchmark",
  // },
  // {
  //   id: "device",
  //   icon: <HiOutlineDeviceMobile />,
  //   label: "Pet's Device",
  // },
  // {
  //   id: "feeder",
  //   icon: <GiDogBowl />,
  //   label: "Feeder",
  // },
  // {
  //   id: "plan",
  //   icon: <FaHandHoldingDollar />,
  //   label: "Plan",
  // },
  // {
  //   id: "feedback",
  //   icon: <VscFeedback />,
  //   label: "Feedback",
  // },
  // {
  //   id: "mode-feedback",
  //   icon: <MdFeedback />,
  //   label: "Mode Feedback",
  // },
  // {
  //   id: "notification",
  //   icon: <IoMdNotificationsOutline />,
  //   label: "Notification",
  // },
  // {
  //   id: "config",
  //   icon: <AiOutlineSetting />,
  //   label: "App Config",
  // },



];
export default data;
