import { Col, Modal, Row } from "antd";
import React from "react";
import { SlCheck, SlClose } from "react-icons/sl";

const AnalyzeModal = ({ analyzeData, setAnalyzeData }) => {
  return (
    <Modal
      title="Analyze User"
      centered
      open={analyzeData}
      width={725}
      footer={false}
      onCancel={() => setAnalyzeData(null)}
    >
      <Row className="my-3">
        <Col span={12}>
          {analyzeData?.isPassed ? (
            <SlCheck className="text-2xl mb-5 mt-2" color="green" />
          ) : (
            <SlClose className="text-2xl mb-5 mt-2" color="red" />
          )}
          <div className="font-medium">
            <span className="text-[#A8B0B8]">User Name :&nbsp;</span>
            {analyzeData?.userName}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Block :&nbsp;</span>
            {analyzeData?.isBlocked ? "Yes" : "No"}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Total Referred Users :&nbsp;</span>
            {analyzeData?.totalReferredUsers}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Task Count :&nbsp;</span>
            {analyzeData?.taskCount}
          </div>
          {Object.entries(analyzeData?.blockedReason || {}).map(
            ([key, value], index) => (
              <div className="my-5 font-medium">
                <span className="text-gray-500">{key} :&nbsp;</span>
                {value}
              </div>
            )
          )}
        </Col>
        <Col span={12}>
          <div className=" font-medium">
            <span className="text-[#A8B0B8]">Withdrawal Amount :&nbsp;</span>
            {analyzeData?.amount}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">
              Duplicate Device Count :&nbsp;
            </span>
            {analyzeData?.duplicateDeviceCount}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Blocked Users Count :&nbsp;</span>
            {analyzeData?.blockedUsersCount}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">
              Remaining Block User Count Above 40% :&nbsp;
            </span>
            {analyzeData?.["Remaining Block User Count Above 40%"]}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default AnalyzeModal;
