import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Popconfirm,
  Row,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import FormFields from "../../../../component/common/FormFields";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { IoIosNotificationsOutline } from "react-icons/io";
import useHttp from "../../../../hooks/use-http";

function Notification() {
  const api = useHttp();
  const [form] = Form.useForm();
  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.sendRequest(
      CONSTANTS.API.Task_Management.getAll,
      (res) => {
        const option = res?.data?.rows?.map((task) => {
          return { Label: task?.name, value: task?.id, id: task?.id };
        });
        setTaskList(option);
        setLoading((pre) => !pre);
      },
      { status: "Active" }
    );
  }, []);

  const handleFormSubmit = (type) => {
    form
      .validateFields()
      .then((value) => {
        if (
          (value?.status?.length && value?.taskId) ||
          (!value?.status?.length && !value?.taskId)
        ) {
          api.sendRequest(
            CONSTANTS.API.notification.send,
            (res) => {
              form.resetFields();
            },
            {
              ...value,
              ...(!value?.taskId && {
                topic: process.env.REACT_APP_NOTIFICATION_TOPIC,
                type: "general",
              }),
            },
            "Notification send successfully"
          );
        } else if (value?.status?.length)
          notification.warning({ message: "Please Select Project" });
        else if (value?.taskId)
          notification.warning({ message: "Please Select Targer User" });
      })
      ?.catch(() => {});
  };

  return (
    <div className="bg-[#f0f2f5] p-14">
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Card
            title="Generel Notification"
            className="h-[500px]"
            bodyStyle={{ height: 400 }}
          >
            {!loading ? (
              <>
                <FormFields
                  formFields={CONSTANTS.FORM_FIELD.GENEREL_NOTIFICATION?.map(
                    (field) => {
                      if (field?.id === "taskId") field.option = taskList;
                      return field;
                    }
                  )}
                  form={form}
                />
                <Popconfirm
                  description={"Are you sure?"}
                  onConfirm={() => handleFormSubmit("general")}
                >
                  <Button
                    type="primary mt-10 flex"
                    icon={<IoIosNotificationsOutline size={25} />}
                    size={"large"}
                  >
                    Send
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <Spin
                size="large"
                tip="Loading..."
                className="w-full flex items-center h-full justify-center"
              />
            )}
          </Card>
        </Col>
        {/* <Col span={12}>
          <Card title="Personal Notification" className="h-[400px]">
            <FormFields
              formFields={CONSTANTS.FORM_FIELD.USER_NOTIFICATION}
              form={form}
            />
            <Popconfirm
              description={"Are you sure?"}
              onConfirm={() => handleFormSubmit("user")}
            >
              <Button
                type="primary mt-5 mb-7"
                icon={<IoIosNotificationsOutline />}
                size={"large"}
              >
                Download
              </Button>
            </Popconfirm>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
}

export default Notification;
