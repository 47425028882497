export const userExportData = [
  ["no", "User Id"],
  ["name", "User Name"],
  ["mobile", "Mobile Number"],
  ["email", "Email"],
  ["isIdentifyD", "Identify"],
  ["isAffiliateD", "Affiliate User"],
  ["isBlockedD", "Block User"],
];

export const deviceUserList = [
  ["no", "User Id"],
  ["name", "User Name"],
  ["mobile", "Mobile Number"],
  ["email", "Email"],
  ["city", "City"],
  ["walletBalance", "Wallet Balance"],
];

export const userVerificationData = [
  ["no", "User Id"],
  ["name", "User Name"],
  ["mobile", "Mobile Number"],
  ["email", "Email"],
  ["isIdentifyD", "Identify"],
];

export const roleManagement = [
  ["no", "User Id"],
  ["name", "User Name"],
  ["email", "Email"],
  ["role", "Role"],
];

export const brandExportData = [
  ["no", "Brand Id"],
  ["logo", "Profile"],
  ["name", "Brand Name"],
  ["isBlockedD", "Block Brand"],
];
export const taskApprovalExportData = [
  ["no", "no"],
  ["name", "Name"],
  ["mobile", "Mobile Number"],
  ["taskId", "Task ID"],
  ["action", "Action"],
  ["adminMessage", "Message"],
  ["taskSubmissionDateTime", "Submission Date Time"],
];
export const taskApprovalExporAlltData = [
  ["no", "no"],
  ["name", "Name"],
  ["mobile", "Mobile Number"],
  ["taskId", "Task ID"],
  ["action", "Action"],
  ["adminMessage", "Message"],
  ["taskSubmissionDateTime", "Submission Date Time"],
  ["link1", "Link 1"],
  ["link2", "Link 2"],
  ["link3", "Link 3"],
  ["link4", "Link 4"],
  ["link5", "Link 5"],
  ["link6", "Link 6"],
  ["link7", "Link 7"],
  ["link8", "Link 8"],
  ["link9", "Link 9"],
  ["link10", "Link 10"],
];
export const taskExportData = [
  ["no", "Task Id"],
  ["brandName", "Brand Name"],
  ["category", "Category"],
  ["name", "Task Heading"],
  ["tag", "Label"],
  ["statusD", "Status"],
  ["amount", "Amount"],
];
export const particularTaskExportData = [
  ["no", "Task Id"],
  ["category", "Category"],
  ["name", "Task Heading"],
  ["tag", "Label"],
  ["statusD", "Status"],
  ["amount", "Amount"],
];
export const taskCategoryExportData = [
  ["no", "Task CategoryId"],
  ["name", "TaskCategory"],
];
export const testimonialExportData = [
  ["no", "Testimonial Id"],
  ["userImage", "Image"],
  ["userName", "Name"],
  ["message", "Message"],
  ["ratingD", "Ratings"],
  ["isBlockedD", "Block Testimonial"],
];
export const faqExportData = [
  ["no", "FAQ Id"],
  ["question", "Question"],
  ["answer", "Answer"],
  ["isBlockedD", "Block Testimonial"],
];
