import { Card, Col, Form, Image, Modal, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsInfoCircle } from "react-icons/bs";
import logo from "../../asset/logos/icon.svg";
import CONSTANTS, { RenderEditButton } from "../../util/constant/CONSTANTS";
import {
  apiGenerator,
  convertUTCToLocal,
  PERMISSION,
} from "../../util/functions";
import TaskForm from "../../page/app/task/TaskForm";
import ArrowIcon from "./ArrowIcon";
import { payloadGenerator } from "./CRUD-Component";
import TaskApproval from "../../page/app/task-approval";
import useHttp from "../../hooks/use-http";

const CommonTaskDetail = ({
  id,
  name,
  completionAmount,
  helpLink,
  isRegion,
  visibleOnApp,
  regions,
  brand,
  completionSpin,
  taskCategory,
  expireDate,
  taskRegions,
  description,
  termAndCondition,
  completionTime,
  priority,
  availableFileType,
  setRefresh,
  taskCategoryId,
  brandId,
  type,
  accessPermission,
  banner,
  trainingUrl,
  trackUrl,
  endGoal,
  message,
  reviewDays,
  extendReviewDays
}) => {
  const api = useHttp();
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [allBrand, setAllBrand] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isRegionBased, setIsRegionBased] = useState(false);
  const [isVisibleOnApp, setIsVisibleOnApp] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onClose = () => {
    setEditData({});
    setRefresh((prev) => !prev);
    form.resetFields();
  };
  const onSubmit = (data) => {
    const payload = payloadGenerator(
      data,
      CONSTANTS.FORM_FIELD.TASK_MODAL,
      true
    );
    const UPDATE_TASK_API = apiGenerator(
      CONSTANTS.API.Task_Management.update,
      {},
      `${editData?.id}`
    );

    api.sendRequest(
      UPDATE_TASK_API,
      (res) => {
        // console.log(res);
        onClose();
      },
      payload,
      "Update Task Successfully!!!"
    );
  };

  const handleShowInApp = (value) => {
    const UPDATE_SHOW_API = apiGenerator(
      CONSTANTS.API.Task_Management.update,
      {},
      `${id}`
    );
    api.sendRequest(
      UPDATE_SHOW_API,
      (res) => {
        setIsVisibleOnApp(value);
      },
      { visibleOnApp: value },
      "Update Task Successfully!!!"
    );
  };

  const RenderBannerImages = (value) => {
    let imageSrc = value;
    if (!imageSrc) {
      imageSrc = logo;
    }
    return (
      <Card
        className="h-[100px] w-[200px] rounded-lg overflow-hidden shadow-lg border border-gray-300"
        bodyStyle={{ padding: 0, display: "flex", justifyContent: "center" }}
      >
        <Image
          height={100}
          width={200}
          style={{ aspectRatio: "172/86", objectFit: "cover", padding: 8 }}
          src={imageSrc}
          alt={value}
        />
      </Card>
    );
  };

  useEffect(() => {
    if (type === "brand" && PERMISSION[accessPermission] !== "READ") {
      const apiEndpoints = [
        CONSTANTS.API.Brand_Management.getAll,
        CONSTANTS.API.setting.task_category.getAll,
      ];
      api.sendBulkRequest(apiEndpoints, (res) => {
        setAllBrand(
          res[0]?.data?.data?.rows?.map((ele) => ({
            label: ele?.name,
            value: ele?.id,
          }))
        );
        // setAllBrandList(res[0]?.data?.data?.rows?.map(ele => ({ label: ele?.name, value: ele?.id })))
        setAllCategory(
          res[1]?.data?.data?.rows?.map((ele) => ({
            label: ele?.name,
            value: ele?.id,
          }))
        );
        // setAllCategoryList(res[1]?.data?.data?.rows?.map(ele => ({ label: ele?.name, value: ele?.id })))
      });
    }
    setIsRegionBased(isRegion);
    setIsVisibleOnApp(visibleOnApp);
  }, [type, accessPermission, isRegion, visibleOnApp]);
  return (
    <Card className="my-5">
      <div className="font-medium flex items-center justify-between">
        <div className="flex items-center gap-2">
          <ArrowIcon
            onClick={() => navigate(-1)}
            className={"cursor-pointer"}
            size={27}
          />
          <p className="text-[#A8B0B8] text-3xl">Task Detail</p>
        </div>

        {type === "brand" &&
          PERMISSION[accessPermission] !== "READ" &&
          RenderEditButton({
            id,
            onClick: () => {
              setEditData({
                id,
                name,
                taskCategoryId,
                brandId,
                completionAmount,
                helpLink,
                isRegionBased,
                isVisibleOnApp,
                regions,
                brand,
                completionSpin,
                taskCategory,
                expireDate,
                taskRegions,
                description,
                termAndCondition,
                completionTime,
                availableFileType,
                priority,
                message,
                reviewDays,
                extendReviewDays
              });
            },
          })}
      </div>
      {/* Task Details */}
      <div className="my-5">
        <p className="text-2xl font-medium">{name}</p>
      </div>
      {/* Task Details */}
      <Row>
        <Col span={11}>
          <div className="font-medium">
            <span className="text-[#A8B0B8]">Task ID :&nbsp;</span>
            {id}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Amount :&nbsp;</span>
            {completionAmount}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">URL :&nbsp;</span>
            <Link className="" to={helpLink} target="_blank">
              {helpLink}
            </Link>
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Track Url :&nbsp;</span>
            <Link className="" to={trackUrl} target="_blank">
              {trackUrl}
            </Link>
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Is Region Based :&nbsp;</span>
            {isRegionBased ? "Yes" : "No"}
          </div>
        </Col>
        <Col span={6}>
          <div className="font-medium">
            <span className="text-[#A8B0B8]">Brand :&nbsp;</span>
            {brand?.name}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">No. of Spin :&nbsp;</span>
            {completionSpin}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Completion Time :&nbsp;</span>
            {completionTime} mins
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">End Goal :&nbsp;</span>
            {endGoal}
          </div>
        </Col>
        <Col span={6}>
          <div className="font-medium">
            <span className="text-[#A8B0B8]">Task Category :&nbsp;</span>
            {taskCategory?.name}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Expiry Date :&nbsp;</span>
            {convertUTCToLocal(expireDate, "DD/MM/YYYY")}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Task Priority :&nbsp;</span>
            {priority}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Review Days :&nbsp;</span>
            {reviewDays}
          </div>
          <div className="my-5 font-medium">
            <span className="text-[#A8B0B8]">Extend Review Days :&nbsp;</span>
            {extendReviewDays}
          </div>
        </Col>
      </Row>
      {/* Region City */}
      <Row>
        <Col span={8}>
          <div className="flex flex-row gap-2">
            {isRegionBased &&
              regions?.split(",")?.map((ele) => (
                <div
                  key={ele?.id}
                  className="font-medium bg-[#DBECFF80] inline-block px-3 py-2 rounded-[30px]"
                >
                  {ele}
                </div>
              ))}
          </div>
        </Col>
      </Row>
      {/* Description && Task Terms & Condition */}
      <div className="mt-5">
        <div>
          <div className="flex justify-between">
            <p>Description</p>
            {type === "task" && (
              <BsInfoCircle
                onClick={() => setIsOpen((prev) => !prev)}
                className="cursor-pointer"
                color="#007BFF"
                size={20}
              />
            )}
          </div>
          <div className="border border-[#D7D7D7] p-3 mt-1.5 h-64 overflow-y-scroll rounded-md">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
        {type === "brand" && (
          <div className="mt-5">
            <p>Description</p>
            <div className="border border-[#D7D7D7] p-3 mt-1.5 h-64 overflow-y-scroll rounded-md">
              <div dangerouslySetInnerHTML={{ __html: termAndCondition }} />
            </div>
          </div>
        )}
      </div>
      {type !== "brand" && (
        <div className="mt-5">
          <p className="mb-2 font-medium">Training URL with banner</p>
          <div className="flex gap-5">
            <div>{RenderBannerImages(banner)}</div>
            <div>
              <div className="my-2 font-medium">
                <span className="text-[#A8B0B8]">URL :&nbsp;</span>
                <Link className="" to={trainingUrl} target="_blank">
                  {trainingUrl}
                </Link>
              </div>
              <div className="my-2 font-medium">
                <span>Visible on App</span>
              </div>
              <div className="my-2 font-medium">
                <Switch onChange={handleShowInApp} checked={isVisibleOnApp} />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Terms & Condition Modal */}
      <Modal
        title="Task Terms & Conditions"
        open={isOpen}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setIsOpen((prev) => !prev)}
      >
        <div className="border border-[#D7D7D7] p-3 mt-4 h-64 overflow-y-scroll rounded-md">
          <div dangerouslySetInnerHTML={{ __html: termAndCondition }} />
        </div>
      </Modal>
      {/* Edit Task */}
      {
        <TaskForm
          allCategory={allCategory}
          allBrand={allBrand}
          form={form}
          open={Object?.keys(editData)?.length > 0}
          onCancel={() => {
            onClose();
          }}
          handleFormSubmit={onSubmit}
          formData={editData}
          loading={api?.isLoading}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          checkAll={checkAll}
          setCheckAll={setCheckAll}
          isRegionBased={isRegionBased}
          setIsRegionBased={setIsRegionBased}
          isVisibleOnApp={isVisibleOnApp}
          setIsVisibleOnApp={setIsVisibleOnApp}
        />
      }
      {type !== "brand" && <TaskApproval />}
    </Card>
  );
};

export default CommonTaskDetail;
