import React, { useMemo, useState } from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { Modal, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import { convertUTCToLocal, PERMISSION } from "../../../util/functions";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import { deviceUserList } from "../../../util/exportData";

const DeviceList = ({
  accessPermission,
  showBlockedOnly,
  setReload,
  reload,
}) => {
  const [view, setView] = useState(null);
  const [reloadModal, setReloadModal] = useState(false);
  const navigate = useNavigate();
  const [openResonModal, setOpenResonModal] = useState(null);
  const api = useHttp();

  const deviceUpdate = (checked) => {
    api.sendRequest(
      CONSTANTS?.API.Device_Management.update,
      () => {
        setView((pre) => ({ ...pre, isBlocked: checked }));
        setReload((prev) => !prev);
      },
      { isBlocked: checked, deviceId: view?.deviceId },
      `Device ${checked ? "Block" : "Unblock"} Successfully`
    );
  };
  const memoizedExtraQuery = useMemo(() => {
    return {
      deviceId: view?.deviceId,
    };
  }, [view?.deviceId]);

  const OnFormSubmitBtn = (res) => {
    const payload = {
      isBlocked: !!openResonModal,
      blockReason: res?.blockReason ?? "",
    };
    const UpdateAPIEnd = {
      ...CONSTANTS?.API.user.update,
    };
    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${openResonModal ?? res}`;
    api.sendRequest(
      UpdateAPIEnd,
      () => {
        setOpenResonModal(null);
        setReloadModal((prev) => !prev);
      },
      payload,
      `User ${payload?.isBlocked ? "Blocked" : "Unblock"} Successfully`
    );
  };
  return (
    <>
      <CRUDComponent
        reload={reload}
        title="User"
        accessPermission={accessPermission}
        GET={{
          API: CONSTANTS.API.Device_Management.getAll,
          extraQuery: showBlockedOnly ? { showBlockedOnly } : {},
          DataModifier: (res, API, Setrefresh) => {
            return res?.map((data) => {
              return {
                ...data,
                no: data?.no,
                view: {
                  id: data?.id,
                  onClick: (id) => {
                    setView({
                      deviceId: data?.deviceId,
                      isBlocked: data?.isBlocked,
                    });
                  },
                },
                blocked: {
                  checked: !data?.isBlocked,
                  id: data?.id,
                  isDisable: PERMISSION[accessPermission] === "READ",
                  onClick: (id, val) => {
                    API.sendRequest(
                      CONSTANTS?.API.Device_Management.update,
                      () => {
                        Setrefresh((prev) => !prev);
                      },
                      { isBlocked: val, deviceId: data?.deviceId },
                      `Device ${val ? "Block" : "Unblock"} Successfully`
                    );
                  },
                },
              };
            });
          },
          column: CONSTANTS.TABLE.USER_DEVICE,
        }}
        isSearch
      />
      <Modal
        title={
          <div
            className="flex justify-between"
            style={{ width: "calc(100% - 35px)" }}
          >
            <p>User List</p>
            <div className="flex">
              <p className="me-2">Block: </p>
              <Switch
                size={"default"}
                onChange={(checked) => deviceUpdate(checked)}
                checked={view?.isBlocked}
                disabled={PERMISSION[accessPermission] === "READ"}
              />
            </div>
          </div>
        }
        centered
        open={view}
        width={1600}
        footer={false}
        onCancel={() => setView(null)}
      >
        <div>
          <CRUDComponent
            title="User"
            reload={reloadModal}
            accessPermission={accessPermission}
            showPagination={false}
            exportData={deviceUserList}
            GET={{
              API: CONSTANTS.API.user.getAll,
              extraQuery: memoizedExtraQuery,
              DataModifier: (res, API, Setrefresh) => {
                console.log(res);
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.id,
                    view: {
                      id: data?.id,
                      onClick: (id) => {
                        navigate(`/app/user/${id}`);
                      },
                    },
                    isBlocked: {
                      checked: !data?.isBlocked,
                      id: data?.id,
                      isDisable: PERMISSION[accessPermission] === "READ",
                      onClick: (id, val) => {
                        val ? setOpenResonModal(id) : OnFormSubmitBtn(id);
                      },
                    },
                    dob: convertUTCToLocal(data?.DOB, "DD/MM/YYYY"),
                    userImage: { image: data?.image, name: data?.name },
                  };
                });
              },
              column: CONSTANTS.TABLE.USERLIST_DEVICE,
            }}
            isSearch
          />
        </div>
      </Modal>
      <ModalFormCreator
        loading={api.isLoading}
        open={openResonModal}
        onCreate={OnFormSubmitBtn}
        onCancel={() => {
          setOpenResonModal(null);
        }}
        menuFields={CONSTANTS.FORM_FIELD.USER_BLOCK_FORM}
        name={"Reason"}
        SubmitName={"Submit"}
      />
    </>
  );
};

export default DeviceList;
